<template>
  <div class="OverviewBuilding">
    <!-- Top -->
    <div class="Top">
      <!-- Building type -->
      <div class="Block">
        <span class="Title">{{ mixWB('TYPE_OF_BUILDING') }}</span>
        <span>{{ buildingTypeCodes[building.data.BYG_ANVEND_KODE] }}</span>
      </div>

      <!-- BBR -->
      <div class="Block">
        <span class="Title">{{ mixWB('BBR_INFO') }}</span>
        <table>
          <tr>
            <td>{{ mixWB('ADDRESS') }}</td>
            <td>{{ address }}</td>
          </tr>
          <tr
            v-for="item in dataKeys"
            :key="item.key">
            <td>{{ mixWB(item.wordbook) }}</td>
            <td>{{ getBBRDataValue(item) }}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- PCB screening -->
    <div class="PCBScreeningWrap">
      <span class="Title">{{ mixWB('PCB_SCREENING') }}</span>
      <PCBScreeningTable :building="building" />
    </div>

    <!-- Profile images -->
    <div class="ImageListWrap">
      <ImageList
        :title="mixWB('PROFILE_IMAGES')"
        :images="buildingProfileImages"/>
    </div>

    <!-- Floor plan images -->
    <div class="ImageListWrap">
      <ImageList
        :title="mixWB('FLOOR_PLANS')"
        :images="buildingFloorPlanImages"/>
    </div>
  </div>
</template>

<script>
import ImageList from '@/components/ResultItems/ImageList.vue'
import buildingTypeCodes from '@/globals/json/building-type-codes.json'
import PCBScreeningTable from '@/components/ResultItems/PCBScreeningTable.vue'
import { mapGetters } from 'vuex'
import { getBBRDataValue } from '@/components/helpers'

export default {
  name: 'OverviewBuilding',
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buildingTypeCodes,
      getBBRDataValue,
      dataKeys: [
        {
          key: 'MatrNr',
          wordbook: 'CADASTRAL_NUMBER',
          type: '',
        },
        {
          key: 'Landsejerlavkode', // TODO: Find final value somehow
          wordbook: 'LAND_OWNER_ASSCOSIATION',
          type: '',
        },
        {
          key: 'OPFOERELSE_AAR',
          wordbook: 'YEAR_BUILT',
          type: 'year',
        },
        {
          key: 'OMBYG_AAR',
          wordbook: 'REMODELING_YEAR',
          type: 'year',
        },
        {
          key: 'BYG_BEBYG_ARL',
          wordbook: 'BUILT_AREA',
          type: 'm2',
        },
        {
          key: 'UTILIZED_ATTIC_AREA',
          wordbook: 'UTILIZED_ATTIC_AREA',
          type: 'm2',
        },
        {
          key: 'BASEMENT_AREA',
          wordbook: 'BASEMENT_AREA',
          type: 'm2',
        },
        {
          key: 'BYG_BOLIG_ARL_SAML',
          wordbook: 'TOTAL_LIVING_AREA',
          type: 'm2',
        },
        {
          key: 'ERHV_ARL_SAML',
          wordbook: 'TOTAL_BUSINESS_AREA',
          type: 'm2',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'addressImages',
      'currentScreeningData',
    ]),
    projectAddress() {
      if (!this.currentScreeningData) {
        return {}
      }

      return this.currentScreeningData.address
    },
    address() {
      return `${
        this.projectAddress.address
      }, ${
        this.projectAddress.postalCode
      } ${
        this.projectAddress.city
      }`
    },
    buildingImages() {
      if (!this.addressImages || !this.addressImages.buildings) {
        return {}
      }

      return this.addressImages.buildings[this.building.buildingID]
    },
    buildingProfileImages() {
      return this.buildingImages.profile || []
    },
    buildingFloorPlanImages() {
      return this.buildingImages.floorPlans || []
    },
  },
  components: {
    ImageList,
    PCBScreeningTable,
  },
}
</script>

<style lang="stylus" scoped>
  .OverviewBuilding
    display block

  .Top
    display flex
    justify-content space-between
    span
      display block
      margin-bottom 2.5px
    .Block
      flex-basis 0
      flex-grow 1
      padding 15px
      border-right 1px solid $color_grey_lighter
      &:last-child
        border-right none
    +below($phablet)
      display block
      .Block
        border-right none
        border-bottom 1px solid $color_grey_lighter
        &:last-child
          border-bottom none

  .Title
    font-weight bold
    margin-bottom 7.5px
    text-transform uppercase

  table
    width 100%
    border-collapse collapse
    tr
      &:nth-child(odd)
        background-color $color_report_grey
      td
        padding 5px
        &:first-child
          padding-right 20px

  .PCBScreeningWrap
    border-top 1px solid $color_grey_lighter
    padding 15px

  .ImageListWrap
    border-top 1px solid $color_grey_lighter

  .NoFloorPlans
    display block
    margin 10px
    font-style italic
    color $color_grey
</style>
