<template>
  <div class="OverviewAddress">
    <!-- Top -->
    <div class="Top">
      <!-- Address -->
      <div class="Block">
        <span class="Title">{{ mixWB('ADDRESS') }}</span>
        <span>{{ projectAddress.address }}</span>
        <span>{{ projectAddress.postalCode }} {{ projectAddress.city }}</span>
      </div>

      <!-- Project description -->
      <div class="Block">
        <span class="Title">{{ mixWB('ABOUT_THE_PROJECT') }}</span>
        <template v-if="metaData">
          <div class="TextWithTitle">
            <span>{{ mixWB('TYPE') }}</span>
            <span>{{ projektTypeValue }}</span>
          </div>
          <div class="TextWithTitle">
            <span>{{ mixWB('DESCRIPTION') }}</span>
            <span>{{ metaData.projectDescription }}</span>
          </div>
        </template>
      </div>

      <!-- Builder -->
      <div class="Block">
        <span class="Title">{{ mixWB('BUILDER') }}</span>
        <template v-if="builder">
          <span>{{ builder.fullName }}</span>
          <span>{{ builder.phoneNumber }}</span>
          <span>{{ builder.email }}</span>
          <span>{{ builder.address.address }}</span>
          <span>{{ builder.address.postalCode }} {{ builder.address.city }}</span>
        </template>
      </div>
    </div>

    <!-- Images -->
    <div
      v-if="addressProfileImages.length"
      class="ImageListWrap">
      <ImageList
        :title="mixWB('PROFILE_IMAGES')"
        :images="addressProfileImages" />
    </div>
    <div
      v-if="addressCadastralMapImages.length"
      class="ImageListWrap">
      <ImageList
        :title="mixWB('CADASTRAL_MAP')"
        :images="addressCadastralMapImages" />
    </div>
  </div>
</template>

<script>
import ImageList from '@/components/ResultItems/ImageList'
import { mapGetters } from 'vuex'

export default {
  name: 'OverviewAddress',
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'metaData',
      'addressImages',
    ]),
    projectAddress() {
      if (!this.currentScreeningData) {
        return {}
      }

      return this.currentScreeningData.address
    },
    builder() {
      return this.metaData.builder || false
    },
    addressProfileImages() {
      if (!this.addressImages?.overview?.profile) {
        return []
      }

      return this.addressImages.overview.profile
    },
    addressCadastralMapImages() {
      if (!this.addressImages?.overview?.cadastralMap) {
        return []
      }

      return this.addressImages.overview.cadastralMap
    },
    projektTypeValue() {
      let type = ''

      if (this.metaData.projectType === 'total-demolition') {
        type = this.mixWB('TOTAL_DEMOLITION')
      }

      if (this.metaData.projectType === 'renovation') {
        type = this.mixWB('RENOVATION')
      }

      return type
    },
  },
  components: {
    ImageList,
  },

}
</script>

<style lang="stylus" scoped>
  .OverviewAddress
    display block

  .Top
    display flex
    justify-content space-between
    span
      display block
      margin-bottom 2.5px
    .Block
      flex-basis 0
      flex-grow 1
      padding 15px 5px 15px 15px
      border-right 1px solid $color_grey_lighter
      &:last-child
        border-right none
    .Title
      font-weight bold
      margin-bottom 7.5px
      text-transform uppercase
    .TextWithTitle
      margin-bottom 10px
      span
        &:first-child
          font-style italic
    +below($phablet)
      display block
      .Block
        border-right none
        border-bottom 1px solid $color_grey_lighter
        &:last-child
          border-bottom none

  .ImageListWrap
    border-top 1px solid $color_grey_lighter

  .CadastralMapWrap
    padding 15px
    border-top 1px solid $color_grey_lighter
    span
      display block
      font-size 0.875rem
      margin-bottom 5px

</style>
