<template>
  <div class="PCBScreeningTable">
    <span
      v-if="!psbScreeningAnswers.length"
      class="EmptyText">{{ mixWB('SCREENING_NOT_MADE_YET') }}</span>
    <table v-if="psbScreeningAnswers.length">
      <tr>
        <th>{{ mixWB('QUESTIONS') }}</th>
        <th>{{ mixWB('YES') }}</th>
        <th>{{ mixWB('NO') }}</th>
        <th>{{ mixWB('DONT_KNOW') }} / {{ mixWB('COMMENTS') }}</th>
      </tr>
      <tr
        v-for="answer in psbScreeningAnswers"
        :key="answer.id">
        <td class="Question">{{ answer.text }}</td>
        <td class="Yes">{{ answer.yes }}</td>
        <td class="No">{{ answer.no }}</td>
        <td class="Comment">{{ answer.dontKnow }} {{ answer.comment }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PCBScreeningTable',
  props: {
    unit: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    building: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'pcbScreeningQuestions',
      'screeningPCBScreenings',
    ]),
    psbScreeningData() {
      if (!this.pcbScreeningQuestions || !this.screeningPCBScreenings) {
        return {}
      }
      let screening = null
      if (this.unit) {
        screening = this.screeningPCBScreenings.find((x) => x.id === this.unit.id)
      }
      else {
        screening = this.screeningPCBScreenings.find((x) => x.id === this.building.buildingID)
      }

      return screening ? screening.data : {}
    },
    psbScreeningAnswers() {
      return this.pcbScreeningQuestions.reduce((prev, question) => {
        if (!this.psbScreeningData[question.id]) {
          return prev
        }

        const data = {
          id: question.id,
          text: '',
          no: '',
          yes: '',
          dontKnow: '',
          comment: '',
        }

        const { answer } = this.psbScreeningData[question.id]

        data.text = this.mixWB(question.wordbook)
        data.no = answer === 0 ? 'X' : ''
        data.yes = answer === 1 ? 'X' : ''
        data.dontKnow = answer === 2 ? 'X' : ''
        data.comment = this.psbScreeningData[question.id].comment || ''

        if (data.dontKnow && data.comment) {
          data.dontKnow = 'X - '
        }

        prev.push(data)
        return prev
      }, [])
    },
  },
  methods: {
    getQuestionAnswer(questionID) {
      if (!this.psbScreeningData || !this.psbScreeningData[questionID]) {
        return ''
      }

      const { answer } = this.psbScreeningData[questionID]

      if (!answer && answer !== 0) {
        return this.mixWB('NOT_FILLED')
      }
      if (answer === 0) {
        return this.mixWB('NO')
      }
      if (answer === 1) {
        return this.mixWB('YES')
      }
      if (answer === 2) {
        return this.mixWB('DONT_KNOW')
      }
      return this.mixWB('DONT_KNOW')
    },
  },
}
</script>

<style lang="stylus" scoped>
  .PCBScreeningTable
    display block

  .EmptyText
    color $color_grey
    font-style italic
    display block
    margin-top 5px

  table
    width 100%
    border-collapse collapse
    tr
      &:nth-child(even)
        background-color $color_report_grey
      th
        padding 5px
        color $color_grey_light
        text-align center
        font-style italic
        &:first-child
          text-align-last left
      td
        padding 5px
        &.Comment
          width 300px
        &.Yes,
        &.No,
        &.Comment
          text-align center
          min-width 80px
</style>
