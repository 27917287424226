<template>
  <div class="Overview">
    <Header/>

    <div class="PageContentWrap">
      <!-- Address -->
      <Accordion
        :title="mixWB('THE_ADDRESS')"
        :bodyComponent="OverviewAddress"
        padding="none" />

      <!-- Key numbers -->
      <Accordion
        :title="mixWB('KEY_NUMBERS')"
        :bodyComponent="OverviewKeyNumbers"
        padding="none" />

      <!-- Units -->
      <Accordion
        v-for="unit in selectedUnits"
        :key="unit.id"
        :title="unit.getFullTitle()"
        :bodyComponent="OverviewUnit"
        :bodyComponentProps="{ unit }"
        padding="none" />

      <!-- Buildings -->
      <Accordion
        v-for="building in selectedBuildings"
        :key="building.buildingID"
        :title="`${ mixWB('BUILDING') } ${ building.data.Bygningsnr }`"
        :bodyComponent="OverviewBuilding"
        :bodyComponentProps="{ building }"
        padding="none" />
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import Header from '@/components/ResultItems/Header.vue'
import OverviewAddress from '@/components/ResultItems/OverviewAddress'
import OverviewUnit from '@/components/ResultItems/OverviewUnit.vue'
import OverviewBuilding from '@/components/ResultItems/OverviewBuilding.vue'
import OverviewKeyNumbers from '@/components/ResultItems/OverviewKeyNumbers'
import { mapGetters } from 'vuex'

export default {
  name: 'Overview',
  data() {
    return {
      OverviewAddress,
      OverviewUnit,
      OverviewBuilding,
      OverviewKeyNumbers,
    }
  },
  computed: {
    ...mapGetters([
      'selectedBuildings',
      'selectedUnits',
    ]),
  },
  components: {
    Header,
    Accordion,
  },
}
</script>

<style lang="stylus" scoped>
  .Overview
    display block

  .PageContentWrap
    max-width 1000px
    margin 0 auto
</style>
