<template>
  <div class="OverviewKeyNumbers">
    <!-- Categories -->
    <div class="Box">
      <span
        v-if="affectedCategories !== false"
        class="Number">{{ affectedCategories }}</span>
      <span class="Text">{{ mixWB('AFFECTED_CATEGORIES') }}</span>
    </div>

    <!-- Types -->
    <div class="Box">
      <span class="Number">{{ screeningTypes && screeningTypes.length }}</span>
      <span class="Text">{{ mixWB('ADDED_TYPES') }}</span>
    </div>

    <!-- Samples -->
    <div class="Box">
      <div class="InnerBox">
        <span class="Number">{{ samplesStatus.filled }}</span>
        <span class="Text">{{ mixWB('DONE_SAMPLES') }}</span>
      </div>
      <div class="InnerBox">
        <span class="Number">{{ samplesStatus.equivalent }}</span>
        <span class="Text">{{ mixWB('EQUIVALENT_SAMPLES') }}</span>
      </div>
      <div class="InnerBox">
        <span class="Number">{{ samplesStatus.skipped }}</span>
        <span class="Text">{{ mixWB('ASSESSED_SAMPLES') }}</span>
      </div>
    </div>

    <!-- Resale -->
    <div class="Box">
      <div class="InnerBox">
        <span class="Number">{{ screeningResaleStatus.yes }}</span>
        <span class="Text">{{ mixWB('RESALES') }}</span>
      </div>
      <div class="InnerBox">
        <span class="Number">{{ screeningResaleStatus.potential }}</span>
        <span class="Text">{{ mixWB('POTENTIAL_RESALES') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OverviewKeyNumbers',
  computed: {
    ...mapGetters([
      'samplesStatus',
      'screeningTypes',
      'screeningResaleStatus',
    ]),
    affectedCategories() {
      if (!this.screeningTypes) {
        return false
      }

      const categories = this.screeningTypes.reduce((prev, item) => {
        if (!prev.includes(item.categoryID)) {
          prev.push(item.categoryID)
        }
        return prev
      }, [])

      return categories.length
    },
  },
}
</script>

<style lang="stylus" scoped>
  .OverviewKeyNumbers
    display flex
    +below($phablet)
      display block

  .Box
    flex-basis 0
    flex-grow 1
    padding 15px 0px 20px
    border-right 1px solid $color_grey_lighter
    flex-center-children-column()
    justify-content center
    &:last-child
      border-right none
    .Number
      font-size 2.5rem
    .Text
      font-size 1.125rem
    .InnerBox
      flex-center-children-column()
      margin-bottom 10px
    +below($phablet)
      border-right none
      border-bottom 1px solid $color_grey_lighter
      &:last-child
        border-bottom none

</style>
