<template>
  <div class="OverviewUnit">
    <!-- Top -->
    <div class="Top">
      <!-- Unit type -->
      <div class="Block">
        <span>{{ mixWB('TYPE_OF_UNIT') }}: {{ unit.getTypeText() }}</span>
        <span v-if="unit.type === 'building' && unit.getBuildingTypeCodeText()">
          {{ unit.getBuildingTypeCodeText() }}
        </span>
      </div>

      <!-- BBR -->
      <div class="Block">
        <UnitBBRSchema
          :unit="unit"
          :address="address" />
      </div>
    </div>

    <!-- PCB screening -->
    <div
      class="PCBScreeningWrap"
      v-if="!unit.hasParent()">
      <UnitPCBSchema :unit="unit" />
    </div>

    <!-- Profile images -->
    <div class="ImageListWrap">
      <ImageList
        :title="mixWB('PROFILE_IMAGES')"
        :images="buildingProfileImages"/>
    </div>

    <!-- Floor plan images -->
    <div class="ImageListWrap">
      <ImageList
        :title="mixWB('FLOOR_PLANS')"
        :images="buildingFloorPlanImages"/>
    </div>
  </div>
</template>

<script>
import UnitBBRSchema from '@/components/PDF/Report/UnitBBRSchema.vue'
import UnitPCBSchema from '@/components/PDF/Report/UnitPCBSchema.vue'
import ImageList from '@/components/ResultItems/ImageList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OverviewUnit',
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'addressImages',
      'currentScreeningData',
    ]),
    projectAddress() {
      if (!this.currentScreeningData) {
        return {}
      }

      return this.currentScreeningData.address
    },
    address() {
      return `${
        this.projectAddress.address
      }, ${
        this.projectAddress.postalCode
      } ${
        this.projectAddress.city
      }`
    },
    buildingImages() {
      if (!this.addressImages?.units?.[this.unit.id]) {
        return {}
      }

      return this.addressImages.units[this.unit.id]
    },
    buildingProfileImages() {
      return this.buildingImages.profile || []
    },
    buildingFloorPlanImages() {
      return this.buildingImages.floorPlans || []
    },
  },
  components: {
    UnitBBRSchema,
    UnitPCBSchema,
    ImageList,
  },
}
</script>

<style lang="stylus" scoped>
  .OverviewUnit
    display block

  .Top
    display flex
    justify-content space-between
    span
      display block
      margin-bottom 2.5px
    .Block
      flex-basis 0
      flex-grow 1
      padding 15px
      border-right 1px solid $color_grey_lighter
      &:last-child
        border-right none
    +below($phablet)
      display block
      .Block
        border-right none
        border-bottom 1px solid $color_grey_lighter
        &:last-child
          border-bottom none

  .Title
    font-weight bold
    margin-bottom 7.5px
    text-transform uppercase

  table
    width 100%
    border-collapse collapse
    tr
      &:nth-child(odd)
        background-color $color_report_grey
      td
        padding 5px
        &:first-child
          padding-right 20px

  .PCBScreeningWrap
    border-top 1px solid $color_grey_lighter
    padding 15px
</style>
